import {pipe} from 'rxjs';
import {customClaims} from '@angular/fire/auth-guard';
import {map} from 'rxjs/operators';


export const redirectIfNotAdmin = () => pipe(customClaims, map(claims => {
  const isAdmin = claims["isAdmin"];
  const isCourseAdmin = claims["isCourseAdmin"];
  return isAdmin || isCourseAdmin ? true : ['/courses'];
}));


export const getUrlParameter = function getUrlParameter(sParam:string) {
  const sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&');

  for (let i = 0; i < sURLVariables.length; i++) {
    const sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? null : decodeURIComponent(sParameterName[1]);
    }
  }
  return null;
};
